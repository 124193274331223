import React from 'react'

import { Grid } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import Text from '~/components/base/Text'

import FindMakerBtn from '../FindMakerBtn'
import SectionContainer from '../SectionContainer'

import StepsItem from './StepsItem'
import msg from './StepsSection.messages'

const StepsSection = () => {
  const { formatMessage } = useIntl()

  const stepsData = [
    {
      id: 1,
      title: formatMessage(msg.step1Title),
      description: formatMessage(msg.step1Description)
    },

    {
      id: 2,
      title: formatMessage(msg.step2Title),
      description: formatMessage(msg.step2Description)
    },

    {
      id: 3,
      title: formatMessage(msg.step3Title),
      description: formatMessage(msg.step3Description)
    }
  ]

  return (
    <SectionContainer>
      <Text
        size={{ xs: 'h3', md: 'h2' }}
        component="h2"
        color="textPrimary"
        textAlign="center"
        fontWeight={600}
        maxWidth={504}
        m="0 auto"
        mb={4}
      >
        <FormattedMessage {...msg.title} />
      </Text>

      <Grid container rowSpacing={{ xs: 3, md: 0.01 }} mb={4}>
        {stepsData.map((step) => {
          return <StepsItem key={step.id} {...step} />
        })}
      </Grid>

      <FindMakerBtn />
    </SectionContainer>
  )
}

export default StepsSection

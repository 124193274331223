import { defineMessages } from 'react-intl'

const msg = defineMessages({
  title: {
    defaultMessage:
      'No hassle. No dirty dishes. A couple of clicks and the cake is ready'
  },
  step1Title: {
    defaultMessage: 'Find a cake maker'
  },
  step1Description: {
    defaultMessage: 'Search your local area for cake makers.'
  },
  step2Title: {
    defaultMessage: 'Order your cake'
  },
  step2Description: {
    defaultMessage:
      'The cake maker will send you a quote to make a cake to your exact requirements for your acceptance.'
  },
  step3Title: {
    defaultMessage: 'Pay and relax'
  },
  step3Description: {
    defaultMessage:
      'Pay your deposit and your cake maker will get to work making your amazing cake.'
  }
})

export default msg

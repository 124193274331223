import React, { FC } from 'react'

import { Grid, Stack, Typography } from '@mui/material'

interface StapsItemProps {
  id: number
  title: string
  description: string
}

const StepsItem: FC<StapsItemProps> = ({ id, title, description }) => {
  return (
    <Grid
      item
      xs={12}
      md={4}
      key={id}
      textAlign="center"
      sx={{
        position: 'relative',

        '&:not(:first-of-type)::before': {
          xs: { display: 'none' },
          md: {
            content: '""',
            position: 'absolute',
            top: 20,
            display: 'block',
            left: 'calc(-50% + 20px)',
            right: ' calc(50% + 20px)',
            borderColor: 'primary.main',
            borderTopStyle: 'dashed',
            borderTopWidth: 1
          }
        },

        '&:last-child::before': {
          right: ' calc(50% + 30px)'
        },

        '&:nth-of-type(2)::before': {
          left: 'calc(-50% + 30px)'
        }
      }}
    >
      <Typography
        position="relative"
        width={40}
        height={40}
        mx="auto"
        mb={2}
        fontSize={20}
        fontWeight={600}
        lineHeight="40px"
        color="#fff"
        sx={{
          backgroundColor: 'third.main',
          borderRadius: '50%'
        }}
      >
        {id}
      </Typography>

      <Stack maxWidth={270} mx="auto">
        <Typography
          variant="h4"
          component="h3"
          fontWeight={700}
          color="text.primary"
          mb={1}
        >
          {title}
        </Typography>

        <Typography variant="body2" color="text.headline">
          {description}
        </Typography>
      </Stack>
    </Grid>
  )
}

export default StepsItem

import { useRouter } from 'next/router'
import React from 'react'

import { Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import ROUTES from '~/utils/constants/routes'

const FindMakerBtn = () => {
  const router = useRouter()

  const handleClick = (): void => {
    router.push(ROUTES.search)
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      sx={{
        display: 'block',
        width: { xs: '100%', sm: 'auto' },
        m: '0 auto',
        py: 1.75,
        px: 1.5,
        fontSize: 17,
        fontWeight: 600
      }}
    >
      <FormattedMessage defaultMessage="Find a Cake Maker" />
    </Button>
  )
}

export default FindMakerBtn
